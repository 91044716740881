export default {
  black: '#000',
  white: '#fff',
  gray: {
    100: '#f7fafc',
    200: '#edf2f7',
    300: '#e2e8f0',
    400: '#cbd5e0',
    500: '#a0aec0',
    600: '#718096',
    700: '#4a5568',
    800: '#2d3748',
    900: '#1a202c'
  },
  red: {
    100: '#FFD3CC',
    200: '#FF9E9A',
    300: '#FF6870',
    400: '#FF425F',
    500: '#ff0442',
    600: '#DB024D',
    700: '#B70251',
    800: '#93014F',
    900: '#7A004D'
  },
  orange: {
    100: '#FFF1CD',
    200: '#FFDF9B',
    300: '#FFC969',
    400: '#FFB344',
    500: '#ff8f06',
    600: '#DB7004',
    700: '#B75503',
    800: '#933E01',
    900: '#7A2D01'
  },
  yellow: {
    100: '#fffff0',
    200: '#fefcbf',
    300: '#faf089',
    400: '#f6e05e',
    500: '#ecc94b',
    600: '#d69e2e',
    700: '#b7791f',
    800: '#975a16',
    900: '#744210'
  },
  green: {
    100: '#F5FDCB',
    200: '#EAFB98',
    300: '#D6F364',
    400: '#C1E73D',
    500: '#A3D804',
    600: '#87B902',
    700: '#6D9B02',
    800: '#547D01',
    900: '#436700'
  },
  teal: {
    100: '#CAFCF0',
    200: '#96FAE9',
    300: '#60F1E5',
    400: '#39E4E4',
    500: '#00C2D3',
    600: '#0097B5',
    700: '#007297',
    800: '#00527A',
    900: '#003B65'
  },
  blue: {
    100: '#ebf8ff',
    200: '#bee3f8',
    300: '#90cdf4',
    400: '#63b3ed',
    500: '#4299e1',
    600: '#3182ce',
    700: '#2b6cb0',
    800: '#2c5282',
    900: '#2a4365'
  },
  indigo: {
    100: '#ebf4ff',
    200: '#c3dafe',
    300: '#a3bffa',
    400: '#7f9cf5',
    500: '#667eea',
    600: '#5a67d8',
    700: '#4c51bf',
    800: '#434190',
    900: '#3c366b'
  },
  purple: {
    100: '#faf5ff',
    200: '#e9d8fd',
    300: '#d6bcfa',
    400: '#b794f4',
    500: '#9f7aea',
    600: '#805ad5',
    700: '#6b46c1',
    800: '#553c9a',
    900: '#44337a'
  },
  pink: {
    100: '#FDCED2',
    200: '#FC9DAE',
    300: '#F66C92',
    400: '#EC4786',
    500: '#E10F74',
    600: '#C10A75',
    700: '#A20770',
    800: '#820466',
    900: '#6C025F'
  }
}

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---blog-core-core-src-templates-collection-author-js": () => import("./../../../blog-core/core/src/templates/collection.author.js" /* webpackChunkName: "component---blog-core-core-src-templates-collection-author-js" */),
  "component---blog-core-core-src-templates-collection-category-js": () => import("./../../../blog-core/core/src/templates/collection.category.js" /* webpackChunkName: "component---blog-core-core-src-templates-collection-category-js" */),
  "component---blog-core-core-src-templates-collection-tag-js": () => import("./../../../blog-core/core/src/templates/collection.tag.js" /* webpackChunkName: "component---blog-core-core-src-templates-collection-tag-js" */),
  "component---blog-core-core-src-templates-post-js": () => import("./../../../blog-core/core/src/templates/post.js" /* webpackChunkName: "component---blog-core-core-src-templates-post-js" */),
  "component---blog-core-core-src-templates-posts-js": () => import("./../../../blog-core/core/src/templates/posts.js" /* webpackChunkName: "component---blog-core-core-src-templates-posts-js" */),
  "component---blog-core-pages-src-pages-404-jsx": () => import("./../../../blog-core/pages/src/pages/404.jsx" /* webpackChunkName: "component---blog-core-pages-src-pages-404-jsx" */),
  "component---blog-core-pages-src-pages-authors-jsx": () => import("./../../../blog-core/pages/src/pages/authors.jsx" /* webpackChunkName: "component---blog-core-pages-src-pages-authors-jsx" */),
  "component---blog-core-pages-src-pages-contact-jsx": () => import("./../../../blog-core/pages/src/pages/contact.jsx" /* webpackChunkName: "component---blog-core-pages-src-pages-contact-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partnerships-jsx": () => import("./../../src/pages/partnerships.jsx" /* webpackChunkName: "component---src-pages-partnerships-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}


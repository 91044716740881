/* eslint-disable import/no-unresolved */
import { Global } from '@emotion/core'
import pageContextProvider from '@helpers/pageContextProvider'
import React from 'react'
import { Box, Flex } from 'theme-ui'

import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'

const Layout = ({ children, pageContext, location }) => (
  <pageContextProvider.Provider value={{ pageContext, location }}>
    <Flex variant="layout.layout">
      <Global styles={(theme) => theme.global(theme)} />
      <Header />
      <Box
        sx={{
          marginTop: ['60px', null, '75px'],
          backgroundColor: '#FCFCFC',
        }}
        variant="layout.body"
      >
        {children}
      </Box>
      <Footer />
    </Flex>
  </pageContextProvider.Provider>
)

export default Layout

export default {
  none: 0,
  sm: `5px`,
  default: `5px`,
  lg: `5px`,
  xl: `5px`,
  xxl: `5px`,
  full: 9999,
  right: `0 0.5rem 0.5rem 0`,
  left: `0.5rem 0 0 0.5rem`,
  top: `0.5rem 0.5rem 0 0`,
  bottom: `0 0 0.5rem 0.5rem`
}

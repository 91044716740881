import common from './common'

export default {
  ...common.button,
  color: `background`,
  bg: `alpha`,
  borderColor: `alpha`,
  ':hover': {
    bg: `alphaDark`,
    borderColor: `alphaDark`
  }
}

import common from './common'

export default {
  ...common.badge,
  bg: `alpha`,
  color: `white`,
  ':hover': {
    color: `white`,
    bg: `alpha`
  }
}

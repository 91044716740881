import 'react-medium-image-zoom/dist/styles.css'

import Loadable from '@loadable/component'
import React from 'react'
import { default as headings } from './Headings'
import { default as Figure } from './Figure'
import { default as Figcaption } from './Figcaption'

import Zoom from 'react-medium-image-zoom'

const styles = {
  zoomWrapper: {
    textAlign: 'center',
    display: 'block',
  },
  img: {
    maxWidth: '100%',
    maxHeight: 570,
    objectFit: 'cover',
    margin: '1rem auto',
    display: 'block',
  }
}

export const ImageZoom= ({ children, ...rest }) => {
  return (
    <Zoom
      wrapElement="span"
      zoomMargin={20}
      {...rest}
    >
      {children}
    </Zoom>
  )
}

export const ZoomableImage = (props) => (
  <div style={styles.zoomWrapper}>
    <ImageZoom>
      <img style={styles.img} {...props} />
    </ImageZoom>
  </div>
)

const Code = Loadable(() => import('./CodeBlock'))

export default {
  figure: Figure,
  figcaption: Figcaption,
  pre: props => props.children,
  code: Code,
  img: ZoomableImage,
  ...headings
}

import { ThemeProvider, appTheme } from '@guess-the-rose/web-shared'
import React, { ComponentType } from 'react'

/**
 * Chakra uses styled system and we use theme-ui. They conflict so if we're pulling in a shared component
 * we need to wrap it in this as a stop gap until we go custom for our blog implementation.
 */
export const withChakra = <Props extends {}>(
  Component: ComponentType<Props>,
) => (props: Props) => {
  return (
    <ThemeProvider theme={appTheme}>
      <Component {...props} />
    </ThemeProvider>
  )
}
